<template lang="pug">
  .new-page
    .header.app-header
      h1.nio-h1.text-primary-darker New Source
      .controls
    .app-loading(v-if="loading")
      v-progress-circular.progress(
        size="80"
        color="#1438F5"
        indeterminate
      )
    NioAlert(
      :visible="createError !== null"
      :message="`Error message: ${createError}`"
      message-title="Error encountered during source creation. Please try again later or contact us if the problem persists. "
      warning
      @dismiss="createError = null"
    )
    NioStepper(
      v-if="steps"
      :ordered-steps="steps"
      :current-step="currentStep"
      :completed-steps="completedSteps"
      final-step-label="Create"
      @nextStep="nextStep"
      @previousStep="previousStep"
      @submit="createSource"
      @stepSelected="stepSelected($event)"
    )
      NioStep(
        v-if="steps.includes('type')"
        :valid="sourceType !== null"
        :summary="mkStepSummary('type')"
        step-name="type"
        simple-summary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          NioAlert(
            :dismissable="canCreateBucketSource"
            :visible="!canCreateBucketSource"
            message="Companies are currently limited to a single managed S3 bucket."
            warning
          ).bucket-limit-alert
          .options
            .opt-card(
              :class="[sourceTypeOptionClass('bucket')]"
              @click="selectSourceType('bucket')"
            )
              .icon
                NioIconFramer(
                  icon-name="display-sources"
                  small
                )
              .option-text
                h4.nio-h4.text-primary-darker Managed AWS S3 Bucket
                .nio-p.text-primary-darker
                  p
                    | Creating an AWS S3 bucket inside Narrative’s AWS account enables Narrative to cover costs such as
                    | storage and API requests.
      NioStep(
        v-if="steps.includes('configure')"
        :valid="isValidSourceConfig(sourceType)"
        :summary="mkStepSummary('configure')"
        step-name="configure"
        simple-summary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          .source-config(v-if="sourceType === 'bucket'")
            .filter
              .title-description
                .filter-title.nio-h4.text-primary-darker AWS Account ID
                .description.nio-p.text-primary-dark
                  p This 12 digit AWS account ID will have access to your bucket.
              .filter-value
                NioTextField(
                  v-model="sourceConfig.accountId"
                  label="AWS Account ID"
                )
                p.nio-p.text-error(v-if="sourceConfig.accountId && !awsAccountIdValidation.valid")
                  | {{ awsAccountIdValidation.errors[0] }}
            .filter
              .title-description
                .filter-title.nio-h4.text-primary-darker Resource ID
                .description.nio-p.text-primary-dark
                  p A short identifier that will be a part of your bucket's name.
                  p A typical choice would be your company's name, lowercased and spaces replaced with -.
              .filter-value
                NioTextField(
                  v-model="sourceConfig_resourceId"
                  label="Resource ID"
                )
                p.nio-p.text-error(v-if="sourceConfig.resourceId && !resourceIdValidation.valid")
                  | {{ resourceIdValidation.errors[0] }}
            .filter.display-block
              .title-description
                .filter-title.nio-h4.text-primary-darker Access Type
                .description.nio-p.text-primary-dark Decide how you want to authenticate and control access to your bucket.
              .filter-value.mb-4
                NioRadioGroup(
                  v-model="sourceConfig.accessType"
                  slat
                )
                  NioRadioButton(
                    label="Bucket Policy (Recommended)"
                    value="bucket-policy"
                  )
                  NioRadioButton(
                    label="IAM Role"
                    value="role"
                  )
              .description.nio-p.text-primary-darker.mb-4(v-if="sourceConfig.accessType === 'bucket-policy'")
                | Grant access to your bucket for any principal within your AWS account, allowing for easy cross-account bucket copies.
              .description.nio-p.text-primary-darker.mb-4(v-if="sourceConfig.accessType === 'role'")
                | Assume an IAM role created and managed by Narrative. Note: <b>cross-account bucket copies are not supported when using this access method</b>.
              .bucket-role-configuration(v-if="sourceConfig.accessType === 'role'")
                .filter
                  .title-description
                    .filter-title.nio-h4.text-primary-darker External ID (Optional)
                    .description.nio-p.text-primary-dark
                      p Increase the security of your bucket by requiring external identifiers.
                      a(href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_roles_create_for-user_externalid.html?icmpid=docs_iam_console" target="_blank") Learn more.
                  .filter-value
                    NioTextField(
                      v-model="sourceConfig.externalId"
                      label="External ID"
                    )
                    p.nio-p.text-error(v-if="sourceConfig.externalId && !externalIdValidation.valid")
                      | {{ externalIdValidation.errors[0] }}
      NioStep(
        :valid="isValidSourceConfig(sourceType)"
        :summary="mkStepSummary('review & create')"
        step-name="review & create"
        simple-summary
      )
        template(v-slot:content)
          NioDivider(horizontal-solo)
          .review(v-if="sourceType === 'bucket'")
            .filter
              .title-description
                .filter-title.nio-h4.text-primary-darker AWS Account ID
              .filter-value
                NioTextField(
                  v-model="sourceConfig.accountId"
                  label="AWS Account ID"
                  disabled
                )
            .filter
              .title-description
                .filter-title.nio-h4.text-primary-darker Resource ID
              .filter-value
                NioTextField(
                  v-model="sourceConfig_resourceId"
                  label="Resource ID"
                  disabled
                )
            .filter
              .title-description
                .filter-title.nio-h4.text-primary-darker Access Type
              .filter-value
                NioTextField(
                  v-model="sourceConfig.accessType === 'bucket-policy' ? 'Bucket Policy' : 'IAM Role'"
                  label="Access Type"
                  disabled
                )
            .filter(v-if="sourceConfig.accessType === 'role' && sourceConfig.externalId")
              .title-description
                .filter-title.nio-h4.text-primary-darker External ID (Optional)
              .filter-value
                NioTextField(
                  v-model="sourceConfig.externalId"
                  label="External ID"
                  disabled
                )
</template>

<script>

import { NioOpenApiModule } from '@narrative.io/tackle-box'
import { NioAlert } from '@narrative.io/tackle-box'

const validAwsAccountIdRegex = new RegExp("^[0-9]+$")
const validResourceIdRegex = new RegExp("^[0-9a-z-]+$")
const validExternalIdRegex = new RegExp("^[a-zA-Z0-9_+=,.@:\\/-]+$")

export default {
  components: { NioAlert },
  data: () => ({
    steps: null,
    currentStep: null,
    completedSteps: [],
    createError: null,
    sourceConfig: {
      accessType: null,
      accountId: null,
      externalId: null,
      resourceId: null
    },
    sources: null,
    sourceType: null,
    loading: true
  }),
  computed: {
    // Hack around issues with updating a v-model asynchronously which happens here because the default value for
    // `sourceConfig.resourceId` depends on the asynchronously loaded `nioUser`.
    sourceConfig_resourceId: {
      get() {
        return this.sourceConfig.resourceId
      },
      set(value) {
        this.sourceConfig.resourceId = value
      }
    },
    canCreateBucketSource() {
      // Companies are limited to a single bucket for now.
      return this.sources !== null && !this.sources.map(source => source.type).includes('bucket')
    },
    awsAccountIdValidation() {
      return this.validateAwsAccountId(this.sourceConfig.accountId)
    },
    externalIdValidation() {
      return this.validateExternalId(this.sourceConfig.externalId)
    },
    resourceIdValidation() {
      return this.validateResourceId(this.sourceConfig.resourceId)
    }
  },
  mounted() {
    NioOpenApiModule.initCallback(this.openApiInit)
  },
  methods: {
    openApiInit() {
      // Need to initialize default resource ID here as we need to wait for `this.nioUser` to be set.
      this.sourceConfig.resourceId = this.defaultResourceId()
      this.getSources().then(_ => {
        this.steps = ['type', 'configure', 'review & create']
        this.currentStep = 'type'
        if (this.canCreateBucketSource) {
          this.sourceConfig.accessType = 'bucket-policy'
          this.sourceType = 'bucket'
        }
        this.loading = false
      })
    },
    getSources() {
      // NB: only S3 bucket sources are supported, so the below only consults /resources/buckets and coerces it into a
      // "source".
      return this.$nioOpenApi.get('/resources/buckets')
        .then(res => {
          this.sources = res.data.records.map((bucket) => {
            return {
              type: 'bucket',
              ...bucket
            };
          });
        })
    },
    createSource() {
      this.loading = true
      switch(this.sourceType) {
        case 'bucket':
          this.$nioOpenApi.post('/resources/buckets', {
            access: {
              type: this.sourceConfig.accessType === 'bucket-policy' ? 'bucket_policy' : 'role',
              external_id: this.sourceConfig.externalId === "" || this.sourceConfig.accessType !== 'role' ? null : this.sourceConfig.externalId,
            },
            account_id: this.sourceConfig.accountId,
            resource_id: this.sourceConfig.resourceId
          }).then(resp => {
            this.loading = false
            this.createError = null
            return parent.postMessage({
              name: 'pageNavigation',
              payload: 'sources'
            },"*")
          }).catch(err => {
            this.loading = false
            this.createError = err?.response?.data?.error_description || err.message || "unknown"
            // Return user to top of page so they can see alert.
            this.scrollToStep(0)
          })
      }
    },
    isValidSourceConfig(type) {
      switch (type) {
        case 'bucket':
          return this.awsAccountIdValidation.valid &&
          this.externalIdValidation.valid &&
          this.resourceIdValidation.valid
        default:
          return false
      }
    },
    validateAwsAccountId(accountId) {
      let errors = []
      if (!validAwsAccountIdRegex.test(accountId) || accountId.length !== 12) {
        errors.push('An AWS account ID must be a 12 digit number')
      }
      return {
        valid: errors.length === 0,
        errors: errors
      }
    },
    validateExternalId(externalId) {
      let errors = []
      if (externalId && !validExternalIdRegex.test(externalId)) {
        errors.push("External IDs must consist of the following: alphanumeric characters, commas, =, ., @, :, /, -, _")
      }
      if (externalId && externalId.length < 2) {
        errors.push('External IDs must be at least 2 characters long')
      }
      if (externalId && externalId.length > 1224) {
        errors.push('External IDs must be less than 1225 characters long')
      }
      return {
        valid: errors.length === 0,
        errors: errors
      }
    },
    validateResourceId(resourceId) {
      let errors = []
      if (!validResourceIdRegex.test(resourceId)) {
        errors.push("Resource IDs must consist of lowercase alphanumeric characters and dashes")
      }
      if (resourceId && resourceId.slice(resourceId.length - 1) === '-') {
        errors.push("Resource IDs cannot end in a dash")
      }
      if (resourceId && resourceId.length < 2) {
        errors.push('Resource Ids must be at least 2 characters long')
      }
      if (resourceId && resourceId.length > 43) {
        errors.push('Resource Ids must be less than 44 characters long')
      }
      return {
        valid: errors.length === 0,
        errors: errors
      }
    },
    selectSourceType(type) {
      switch(type) {
        case 'bucket':
          if (this.canCreateBucketSource) {
            this.sourceType = type
          }
        default:
          break;
      }
    },
    sourceTypeOptionClass(type) {
      switch(type) {
        case 'bucket':
          if (this.sourceType === type && this.canCreateBucketSource) {
            'selected'
          } else if (!this.canCreateBucketSource) {
            return 'disabled'
          }
        default:
          break;
      }
    },
    defaultResourceId() {
      // If we wanted to be fancy we could fold diacritics by normalizing to NFD and throwing away diacritic UTF code
      // points, but this is probably good enough.
      const resourceId = this.nioUser.companyName
        .toLowerCase()
        // throw away periods and commas
        .replaceAll(/\.,/g, '')
        // replace all non-alphanumeric characters with dashes
        .replaceAll(/[^0-9a-z-]/g, "-")
        // remove consecutive dashes
        .replaceAll(/-+/g, "-")
        // remove leading dashes
        .replace(/^-/, "")
      return this.validateResourceId(resourceId).valid ? resourceId : null
    },
    mkStepSummary(stepName) {
      switch (stepName) {
        case 'type':
          return {
            title: this.mkSourceTypeSummary(this.sourceType)
          }
        case 'configure':
          return {
            title: this.mkSourceConfigSummary(this.sourceType)
          }
        default:
          break;
      }
    },
    mkSourceConfigSummary(type) {
      switch(type) {
        case 'bucket':
          return `${this.sourceConfig.accountId} - ${this.sourceConfig.resourceId}`
        default:
          break;
      }
    },
    mkSourceTypeSummary(type) {
      switch(type) {
        case 'bucket':
          return "S3 Bucket"
        default:
          return
      }
    },
    nextStep() {
      if (!this.completedSteps.includes(this.currentStep)) {
        this.completedSteps.push(this.currentStep)
      }
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) + 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    previousStep() {
      this.currentStep = this.steps[this.steps.indexOf(this.currentStep) - 1]
      this.scrollToStep(this.steps.indexOf(this.currentStep))
    },
    stepSelected(stepName) {
      this.currentStep = stepName
    },
    setStepIncomplete(stepName) {
      const stepIndex = this.completedSteps.indexOf(stepName)
      this.completedSteps = this.completedSteps.filter((step, index) => {
        index < stepIndex
      })
      this.steps.map((step, index) => {
        if (index >= stepIndex) {
          this.stepPayloads[step] = null
        }
      })
    },
    stepComplete(stepName) {
      return this.completedSteps.includes(stepName)
    },
    scrollToStep(stepIndex) {
      this.$nextTick(() => {
        const top = 35 + stepIndex * 130
        parent.postMessage({
          name: 'scrollTo',
          payload: {
            x: 0,
            y: top
          }
        },"*")
      })
    }
  }
}
</script>

<style lang="sass" scoped>

@import "@narrative.io/tackle-box/src/styles/global/_colors"

.new-page
  padding: 24px
  .header
    display: flex
    justify-content: space-between
    align-items: flex-start
    position: relative
    margin-bottom: 32px
    .nio-button
      position: absolute
      right: 40px
  ::v-deep .nio-step-header-slat
    padding-top: 22px
  .nio-divider
    margin-top: -20px
  ::v-deep .v-expansion-panel-content__wrap
    padding: 0px
  ::v-deep .nio-step-content-body
    position: relative
    .creating-subscription
      width: 100%
      height: 100%
      position: absolute
      .v-progress-circular
        position: relative
        left: 50%
        top: 100px
        margin-left: -2.5rem
        z-index: 2
  .description-summary
    display: flex
    flex-direction: column
    p
      margin-top: 4px
    .tag
      margin-right: 8px
  .nio-step-name-offers
    ::v-deep .nio-summary-slat
      padding: 0px
    ::v-deep .offers-summary
      width: 100%
      width: 640px
      .offer
        padding: 16px 24px
        display: flex
        justify-content: space-between
      .offer + .offer
        border-top: 1px solid $c-primary-lighter

  .options
    display: flex
    justify-content: space-around

    .opt-card
      display: flex
      align-items: center
      background-color: $c-canvas
      border: 2px solid $c-primary-lighter
      border-radius: 12px
      padding: 1rem
      margin: 1rem
      width: 100%
      cursor: pointer

      .coming-tag
        display: flex
        align-items: center
        justify-content: space-between
        width: 100%

      .icon
        display: flex
        justify-content: center
        align-items: center
        min-width: 4rem
        min-height: 4rem
        margin-right: 1.5rem
        .nio-icon-framer
          background: $c-primary
          border-color: $c-primary
          ::v-deep svg
            path
              stroke: white

    .selected
      border-color: $c-primary
    .disabled
      opacity: 0.5
      cursor: not-allowed
  .filter
    display: grid
    grid-template-columns: 1fr 1fr
    grid-gap: 1rem
    align-items: center
    width: 100%
    border: 1px solid $c-primary-lighter
    border-bottom: 0px
    padding: 1rem

    &:first-child
      border-radius: 12px 12px 0 0

    &:last-child
      border-radius: 0 0 12px 12px
      border-bottom: 1px solid $c-primary-lighter

    &:only-child
      border-radius: 12px 12px 12px 12px
      border-bottom: 1px solid $c-primary-lighter

    .filter-value
      width: 100%
      .nio-text-field
        margin-bottom: 0

  .display-block
    display: block

  .source-config
    width: 100%

  .review
    background: $c-canvas
    padding: 2rem 2rem 2rem 2rem

  .bucket-limit-alert
    margin: 1rem
    // Override default of 'witdth: 100%' to align alert with option cards
    width: inherit
</style>